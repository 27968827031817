import React, { Component, Suspense } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const AppLayout = ({children}) => {
    return (
        <div>
            <div className="page-wrapper">
                <div className="page-body-wrapper">
                    <Navbar />
                    <div className="page-body">
                        {children}
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    );
}

export default AppLayout;