import React, { Component } from 'react';

class FooterLink extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="footer-alt bg-dark">
                    <p style={{color:'white'}} className="copy-rights"> 2022 © NIEC Indonesia</p>
                </div>
            </React.Fragment>
        );
    }
}

export default FooterLink;