import React, { Component } from 'react';

class LinkSection extends Component {
    render() {
        return (
            <React.Fragment>
                    <h4>{this.props.title}</h4>
                    <ul className="list-unstyled footer-list">
                        {
                            this.props.links.map((fLink, key) =>
                                <li key={key}><a style={{fontWeight:'600'}} rel='noreferrer' href={fLink.link} target='_blank'>{fLink.title}</a></li>
                            )
                        }
                    </ul>
            </React.Fragment>
        );
    }
}

export default LinkSection;