import React, { Fragment } from "react";
import { BrowserRouter, Route, Routes, Switch } from 'react-router-dom';
import Index from "./components";
import Section from "./components/Section";
import Detail from "./components/DetailEvent";
import "./theme.scss";
import "./assets/css/themify-icons.css";
import "./assets/css/style.css";

export default function App() {
    return (
      <>
        <BrowserRouter>
          <Switch>
            <Fragment>
              <Index>
                <Route path="/" exact component={Section} />
                <Route path="/detail/:code" exact component={Detail} />
              </Index>
            </Fragment>
          </Switch>
        </BrowserRouter>
      </>
    );
}
