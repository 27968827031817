import React, { Component } from 'react';
import { Container, Row, Col } from "reactstrap";

import logo from "../../assets/images/logo-niec-white.png";

import FooterLink from "./footer-link";
import LinkSection from "./link-section";

class Footer extends Component {
    state = {
        links1 : [
            { link : "https://niecindonesia.com/", title : "Website" },
            { link : "http://prioritas.niecindonesia.com/", title : "Prioritas Member" },
            { link : "http://ambassador.niecindonesia.com/", title : "Global Ambassador" }
        ],
        socials : [
            { class : "bg-twitter", icon : "ti-twitter-alt", link : "https://twitter.com/NIEC_Indonesia" },
            { class : "bg-dribbble", icon : "ti-instagram", link : "https://www.instagram.com/niec_indonesia/" },
            { class : "bg-googleplus", icon : "ti-youtube", link : "https://www.youtube.com/channel/UCA5qiOgJdfMvADpyU7Oz7fQ" },
            { class : "bg-facebook", icon : "ti-facebook", link : "https://www.facebook.com/Naresy-International-Education-Consultant-578610318865001" },
        ]
    }
    render() {
        return (
            <React.Fragment>
                <footer className="bg-niec footer">
                    <Container>
                        <Row>

                            <Col md="4" sm="12">
                                <img src={logo} alt="logo" width='120' height="55" />
                                <p className="margin-t-20" style={{color: 'white'}}><strong>NIEC Indonesia adalah konsultan pendidikan luar negeri yang membantu pencarian sekolah/college/universitas di Luar Negeri dan telah berpengalaman lebih dari 12 tahun.</strong></p>

                                <ul className="list-inline social">
                                    {
                                        this.state.socials.map((social, key) =>
                                            <li key={key} className="list-inline-item">
                                                <a className={social.class + " mr-1"} rel='noreferrer' href={social.link} target='_blank' style={{color: 'white'}}><i className={social.icon}></i></a>
                                            </li>
                                        )
                                    }
                                </ul>
                            </Col>

                            <Col md={{size:3, offset : 2}} sm="6" style={{color: 'white'}}>
                                <LinkSection title="Links" links={this.state.links1} />
                            </Col>
                        </Row>

                    </Container>
                </footer>
                <FooterLink/>
            </React.Fragment>
        );
    }
}

export default Footer;